<template>
  <div class="main-wrapper">
    <div :class="{ 'fixed-header': !$route.meta.noStickyHead }" class="">
      <Header v-if="user !== 'ban'" />  
    </div>

    <router-view v-if="user !== 'ban'" />
    <div v-else>
      <Error
        class="allPage"
        :title="'Доступ заблокирован'"
        :description="'Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут'"
      />
    </div>
    <div class="bottom-tip" v-if="user !== 'ban'">
      <div class="content">
        Материал предназначен для специалистов здравоохранения. ООО “АстраЗенека Фармасьютикалз”, Москва 123112, БЦ “ОКО”, 1-ый Красногвардейский проезд, д.21, стр.1, 30 этаж. Тел.: +7 (495) 799-56-98, www.astrazeneca.ru Номер одобрения RU-24022. Дата одобрения 12.03.2025. Дата истечения 11.03.2027.
      </div>
    </div>
    <Footer v-if="user !== 'ban'" />
    <Helper
      v-if="showHelper && helperTargets[currentTarget]"
      @setPosition="setPosition"
      @nextStep="nextStep"
      :target="helperTargets[currentTarget]"
      :position="helperPosition"
      :index="currentTarget"
      :length="helperTargets.length"
      @close="closeOnboarding"
    />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import Error from "@/views/Error.vue";
import { mapGetters, mapActions } from "vuex";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import Helper from "@/components/main/Helper.vue";
import HelperStart from "@/components/main/HelperStart.vue";
import { bus } from "@/main";
export default {
  components: { Header, Footer, Error, PopUpDefault, Helper, HelperStart },
  name: "main-layout",
  data: () => ({
    interestSended: false,
    lastKnownScrollY: 0,
    currentScrollY: 0,
    ticking: false,
    eleHeader: null,
    headerContainer: null,
    classes: {
      pinned: "header-pin",
      unpinned: "header-unpin",
    },
    showHelper: false,
    helperStart: false,
    helperText: {
      title: "Настройка «Моего AZ–MOST»",
      description:
        "Настройте портал по интересующим терапевтическим областям и заболеваниям",
    },
    currentTarget: 0,
    helperPosition: {},
  }),
  computed: {
    ...mapGetters(["user", "pageVerify", "Nosologys"]),
    helperTargets() {
      return [
        {
          title: "Воспользуйтесь фильтром",
          description:
            "Чтобы отсортировать материалы по одному или нескольким критериям",
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/onboarding/filter/icon1.svg`,
          key: "filterElem",
          position: "bottom",
        },
      ];
    },
    pageApproval() {
      return this.pageVerify && this.pageVerify.length
        ? this.pageVerify
        : this.$route.meta.pageVerify || [];
    },
    tpNounSpeciality() {
      return this.tpNoun.find(
        (el) => el.value.toLowerCase() === this.user.speciality.toLowerCase()
      ).noun;
    },
  },
  methods: {
    ...mapActions(["fetchPageVerify", "fetchNosologys", "checkTutorial"]),
    sendInterest() {
      this.$axios({
        method: "POST",
        url: "/api/user/click",
      })
        .then((response) => {
          this.interestSended = true;
        })
        .catch((error) => {});
    },
    onScroll() {
      this.currentScrollY = window.pageYOffset;
    },
    update() {
      this.headerContainer = document.querySelector(".fixed-header");
      if (!this.headerContainer) return;
      this.eleHeader = this.headerContainer.querySelector(".header");
      if (this.currentScrollY < this.lastKnownScrollY) {
        this.pin();
      } else if (
        this.currentScrollY > this.lastKnownScrollY &&
        this.currentScrollY > this.eleHeader.offsetHeight
      ) {
        this.unpin();
      }
      this.lastKnownScrollY = this.currentScrollY;
    },

    startHelp() {
      this.helperStart = false;
    },
    closeHelp(check) {
      if (check) {
        this.checkTutorial({ id: this.user.id, flags: ["filter"] });
      }
      this.helperStart = false;
    },

    startOnboarding() {
      setTimeout(() => {
        this.showHelper = true;
        let routeArray = this.$route.path.split("/");
        ym(91468266, "reachGoal", "onboarding", {
          onboarding: {
            [routeArray[routeArray.length - 1]]: 1,
            ...this.$root.ymFields,
          },
        });
      }, 1000);
    },
    closeOnboarding() {
      this.showHelper = false;
      document.querySelector("body").style.pointerEvents = "";
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
      bus.$emit("scrollLock", false);
      bus.$emit("closeFilter");
      document.querySelector(".fixed-header .header").style.position = "";
      this.checkTutorial({ id: this.user.id, flags: ["filter"] });
    },

    setPosition(target) {
      document.querySelector("body").style.pointerEvents = "none";
      bus.$emit("scrollLock", true);
      let relativePos = {};
      if (!target.key && document.querySelector(`[data-id="${target.key}"]`))
        return;
      let child = document.querySelector(`[data-id="${target.key}"]`);
      let parentPos = document.body.getBoundingClientRect();
      let childPos = child.getBoundingClientRect();
      if (!target.key.includes("header")) {
        const yOffset = -100;
        const y =
          child.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      child.classList.add("highlightedElement");
      relativePos.top = childPos.top + window.pageYOffset;
      relativePos.right = childPos.right - parentPos.right;
      relativePos.bottom = childPos.bottom - parentPos.bottom;
      relativePos.left = childPos.left;
      relativePos.childWidth = childPos.width;
      relativePos.childHeight = childPos.height;
      this.helperPosition = relativePos;
    },
    nextStep() {
      let routeArray = this.$route.path.split("/");
      if (this.currentTarget < this.helperTargets.length - 1) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding", {
            onboarding: {
              [routeArray[routeArray.length - 1]]: this.currentTarget + 1,
              ...this.$root.ymFields,
            },
          });
        }
        this.currentTarget++;
        this.setPosition(this.helperTargets[this.currentTarget]);
      } else {
        this.showHelper = false;
        document.querySelector("body").style.pointerEvents = "";
        document.querySelectorAll(".highlightedElement").forEach((item) => {
          item.classList.remove("highlightedElement");
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        bus.$emit("scrollLock", false);
        bus.$emit("closeFilter");
        this.checkTutorial({ id: this.user.id, flags: ["filter"] });
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding finish - filters", {
            onboarding: {
              finish: "filters",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
  },
  beforeDestroy() {
    bus.$off("startFilterHelp", this.startOnboarding);
  },
  created() {
    bus.$on("startFilterHelp", this.startOnboarding);
  },
  async mounted() {
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }
    this.headerContainer = document.querySelector(".fixed-header");
    if (this.headerContainer) {
      this.eleHeader = this.headerContainer.querySelector(".header");
      this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
    }  
  
  },
};
</script>

<style lang="scss" scoped>
.fixed-header {
  position: relative;
  @media screen and (min-width: 1221px) {
    height: 97px;
  }
  & .header {
    @media screen and (min-width: 1221px) {
      position: relative;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #fff;
      transition: 0.3s;
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: 1221px) {
    // padding-top: 130px;
  }
  @media screen and (max-width: 1220px) {
    padding-top: 0px !important;
  }
}

.bottom-tip {
  margin-top: 80px;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  @media screen and (max-width: 1220px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>